<template>

    <div v-if="league && detailData" class="league">

        <LeagueSubNavigation v-if="dataReady" @reinit="init" :league="league" :season="seasonInfo" :baseUrl="baseUrl" :otherSeasons="detailData.seasons"></LeagueSubNavigation>
        
        <div v-bind:key="-league.id" class="container">
            <div class="grid">

                <div class="col-12">

                    <div class="grid">

                        <div class="col-12">
                            <!-- Selection Mobile (Gesamt/Heim/Auswärts) -->
                            <div class="tabselection hidden-md-up">
                                <div class="tabselection__content">
                                    <div :class="tableVariant == 'full' ? 'tabselection__item tabselection__item--active' : 'tabselection__item'" @click="setTableVariant('full')">
                                        Gesamt
                                    </div>
                                    <div :class="tableVariant == 'home' ? 'tabselection__item tabselection__item--active' : 'tabselection__item'" @click="setTableVariant('home')">
                                        Heim
                                    </div>
                                    <div :class="tableVariant == 'away' ? 'tabselection__item tabselection__item--active' : 'tabselection__item'" @click="setTableVariant('away')">
                                        Auswärts
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8">
                            <RankingItem ref="leagueTable" :key="league.fmpSeasonId" :competitionId="league.id" :seasonId="league.fmpSeasonId" @highlightRankings="updateLegend"/>

                            <!-- Hinweis für Punktabzug unterhalb von Tabelle (Desktop)-->
                            <div v-if="league.fmpSeasonId == 1544" class="rankinghint">
                                Abzug von 4 Pluspunkten SV Werder Bremen wegen Verstoß gegen die Lizenzierungsrichtlinien.
                                <br/>Das Spiel Lintfort - Regensburg wurde aufgrund des Einsatzes einer nicht spielberechtigten Spielerin mit 0:2 Punkten und 0:0 Toren gewertet.
                            </div>
                        </div>

                        <div class="col-md-4">

                            <!-- Selection Desktop (Gesamt/Heim/Auswärts) -->
                            <div class="tabselection hidden-sm-down">
                                <div class="tabselection__content">
                                    <div :class="tableVariant == 'full' ? 'tabselection__item tabselection__item--active' : 'tabselection__item'" @click="setTableVariant('full')">
                                        Gesamt
                                    </div>
                                    <div :class="tableVariant == 'home' ? 'tabselection__item tabselection__item--active' : 'tabselection__item'" @click="setTableVariant('home')">
                                        Heim
                                    </div>
                                    <div :class="tableVariant == 'away' ? 'tabselection__item tabselection__item--active' : 'tabselection__item'" @click="setTableVariant('away')">
                                        Auswärts
                                    </div>
                                </div>
                            </div>

                            <!-- Legende -->
                            <div class="ranking__legend">

                                <div v-if="legend" class="ranking__legend__section">
                                    <div class="ranking__legend__title">
                                        Platzierung
                                    </div>
                                    <div  v-for="l in legend" v-bind:key="l.id"  :class="'ranking__legend__item ranking__legend__item--'+l.color">
                                        {{ l.outcome }}
                                    </div>
                                </div>

                                <div class="ranking__legend__section ranking__legend__section--dots">
                                    <div class="ranking__legend__title">
                                        Letzte 5 Spiele
                                    </div>
                                    <div class="ranking__legend__item ranking__legend__item--win">
                                        Sieg
                                    </div>
                                    <div class="ranking__legend__item ranking__legend__item--draw">
                                        Unentschieden
                                    </div>
                                    <div class="ranking__legend__item ranking__legend__item--lose">
                                        Niederlage
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div> <!-- Close .grid -->
        </div> <!-- Close .container -->

    </div>

</template>

<script>

import RankingItem from '@/components/RankingItem.vue'
import LeagueSubNavigation from '@/components/LeagueSubNavigation.vue';

export default {
    name: 'LeagueRankingView',
    components: {
        RankingItem, LeagueSubNavigation,
    },
    data() {
        return {
            league: null,
            competitions: null,
            legend: null,
            seasonInfo: null,
            detailData: null,
            dataReady: false,
            tableVariant: "full",
        }
    },
    /* TODO: Nur ein Workaround - muss umgebaut werden á la SportlerPlus mit meta {} im router.js */
    mounted () {
        document.body.classList.add('league');
        this.init(this.$route.params.id);
    },
    unmounted () {
        document.body.classList.remove('league');
    },
    methods: {
        setTableVariant(v) {
            this.tableVariant = v;
            this.$refs.leagueTable.setTableVariant(v);
        },
        init(leagueId) {
            this.dataReady = false;
            document.body.classList.add('league');
            this.leagueId = parseInt(leagueId);
            this.$store.dispatch("fetchCompetitions").then( () => {
                this.competitions = this.$store.getters.getCompetitions;
                this.league = this.competitions.find(el => el.id == this.leagueId);
                
                if (this.league) {
                    this.$store.dispatch("fetchData",  {filename: "leagues/league_" + this.league.fmpSeasonId + ".json"}).then( (res) => {
                        this.detailData = res;
                        var season_key = this.league.fmpSeasonId;
                        this.seasonInfo = this.detailData.seasons.find(s => s.fmpSeasonId == season_key);

                        this.$nextTick(() => { this.dataReady = true });

                    });
                }
            });
        },
        updateLegend(l) {
            this.legend=l;
        }
    },
    beforeRouteUpdate (to, from, next) {
        this.init(to.params.id);
        next();
    },
    computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        }
    }
}
</script>

<style lang="scss">

    .ranking__legend {
        display: flex;

        @media (min-width: $breakpoint-md) {
            flex-direction: column;
        }

        &__section {

            &:not(:last-child) {
                margin-right: 32px;

                @media (min-width: $breakpoint-md) {
                    margin-right: 0;
                    margin-bottom: 8px;
                }
            }

            @media (min-width: $breakpoint-md) {
                padding: 14px 24px;
            }
        }

        &__title {
            font-size: 11px;
            font-weight: 700;
            color: rgba($colorBlack, .32);
            margin-bottom: 12px;

            @media (min-width: $breakpoint-md) {
                font-size: 12px;
            }
        }

        &__item {
            position: relative;
            display: flex;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            color: rgba($colorBlack, .64);
            padding-left: 10px;

            &:not(:last-child) {
                margin-bottom: 8px;

                @media (min-width: $breakpoint-md) {
                    margin-bottom: 12px;
                }
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 2px;
                height: 100%;

                .ranking__legend__section--dots & {
                    top: calc(50% - 2px);
                    border-radius: 2px;
                    width: 4px;
                    height: 4px;
                }
            }

            &--blue:before {
                background: #247FED;
            }

            &--turquoise:before {
                background: #41D4ED;
            }

            &--orange:before {
                background: #EC8608;
            }

            &--red:before {
                background: #CF1542;
            }

            &--win:before {
                background: $colorSuccess;
            }

            &--draw:before {
                background: rgba($colorBlack, .32);
            }

            &--lose:before {
                background: $colorDanger;
            }
        }
    }

    .tabselection {
        width: 100%;

        &__content {
            display: flex;
            flex-direction: row;

            @media (min-width: $breakpoint-md) {
                flex-direction: column;
                padding: 0 24px 16px;
                max-width: 200px;
            }
        }

        &__item {
            display: flex;
            justify-content: center;
            flex: 1;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0;
            color: $colorPrimary;
            background: rgba($colorPrimary, .08);
            padding: 8px 16px;
            transition: all .2s ease-in-out;

            .tabselection--small & {
                @media screen and (max-width: 768px) {
                    font-size: 11px;
                }
            }

            &:hover {
                background: rgba($colorPrimary, .12);
                cursor: pointer;
            }

            &:first-child {
                border-radius: 4px 0 0 4px;

                @media (min-width: $breakpoint-md) {
                    border-radius: 4px 4px 0 0;
                }
            }

            &:last-child {
                border-radius: 0 4px 4px 0;

                @media (min-width: $breakpoint-md) {
                    border-radius: 0 0 4px 4px;
                }
            }

            &:not(:last-child) {
                margin-right: 1px;

                @media (min-width: $breakpoint-md) {
                    margin-right: 0;
                    margin-bottom: 1px;
                }
            }

            &--active {
                background: $colorPrimary;
                color: $colorWhite;

                &:hover {
                    background: $colorPrimary;
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    .rankinghint {  
        margin-top: 12px;

        @media (max-width: $breakpoint-sm) {
            margin: 16px 0 -4px;
            padding: 0;
        }
    }
</style>